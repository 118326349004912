'use client'

import React, { useCallback, useMemo } from 'react'

import { Menu } from '@circlefin/components/lib/Menu'
import {
  CWBreadCrumb,
  CWBreadCrumbItem,
} from '@features/common.components/ComponentsWeb/BreadCrumb'
import { TextLink } from '@features/common.components/TextLink'
import { useRouter } from 'next/navigation'

import type { NavigationItem } from '@features/navigation.graphql'

/**
 * Properties of the `MobileBreadcrumb` component.
 */
interface MobileBreadcrumbProps {
  /**
   * The navigation items to display.
   */
  items: NavigationItem[]
}

export const MobileBreadcrumb: React.FC<MobileBreadcrumbProps> = ({
  items,
}) => {
  const router = useRouter()

  const handleMenuItemClick = useCallback(
    (url: string) => () => {
      router.push(url)
    },
    [router],
  )

  const [firstItem, lastItem, middleItems] = useMemo(
    () => [
      items.at(0),
      items.at(-1),
      items.length >= 3 ? items.slice(1, -1) : undefined,
    ],
    [items],
  )

  if (items.length <= 1) {
    return null
  }

  return (
    <CWBreadCrumb className="flex-wrap [&_li]:max-w-fit [&_ol]:flex-wrap [&_li]:capitalize [&_li:nth-child(2)]:relative [&_li:nth-child(2)]:overflow-visible sm:hidden">
      {firstItem && (
        <CWBreadCrumbItem>
          <TextLink href={firstItem.url} size="sm" variant="secondary">
            {firstItem.label}
          </TextLink>
        </CWBreadCrumbItem>
      )}

      {middleItems && (
        <CWBreadCrumbItem>
          {/* invisible element to force the bread crumb item width */}
          <span className="invisible">---</span>

          <Menu className="absolute right-0 top-0 z-10">
            <Menu.Icon name="DotsHorizontalSolid" size={20} />
            <Menu.Options anchorPosition="left" position="bottom">
              {middleItems.map((item, index) => (
                <React.Fragment key={item.url}>
                  {index > 0 && <Menu.Divider />}
                  <Menu.Item
                    disabled={!item.url}
                    label={item.label}
                    onClick={handleMenuItemClick(item.url)}
                  />
                </React.Fragment>
              ))}
            </Menu.Options>
          </Menu>
        </CWBreadCrumbItem>
      )}

      {lastItem && (
        <CWBreadCrumbItem>
          <TextLink href={lastItem.url} size="sm" variant="secondary">
            {lastItem.label}
          </TextLink>
        </CWBreadCrumbItem>
      )}
    </CWBreadCrumb>
  )
}
