'use client'

import { Fragment, type FC, useCallback } from 'react'

import { Sidenav } from '@circlefin/components/lib/Sidenav'
import classNames from 'classnames'
import { usePathname } from 'next/navigation'

import { NavItem } from '../NavItem/NavItem'
import { isItemActive } from '../utils'

import type { NavigationItem, NavigationSection } from '../types'

export interface NavSection {
  section: NavigationSection
}

export const NavSection: FC<NavSection> = ({ section }) => {
  const pathname = usePathname()

  /**
   * Check if the item (or it's children when available) is active.
   */
  const isActive = useCallback(
    (item: NavigationItem) => isItemActive(item, pathname),
    [pathname],
  )

  return (
    <div className="border-b 1 pt-4 pb-4 border-neutral-subtle [&_h3]:text-neutral">
      <Sidenav.Group as="h3" heading={section.label}>
        {section.items.map((groupItem, subIndex) => {
          if (groupItem.isHidden) return null
          return (
            <Fragment key={`${groupItem.url}-${subIndex}`}>
              <NavItem
                className={classNames(
                  'p-0.5 transition-gradient duration-300',
                  {
                    'border-blue-300 font-circular-bold': isActive(groupItem),
                  },
                  {
                    'hover:bg-primary-subtle-hovered':
                      groupItem.url !== pathname,
                  },
                )}
                href={groupItem.fallbackUrl ?? groupItem.url}
                label={groupItem.label}
              />
              {groupItem.children &&
                groupItem.children.map((innerGroupItem, innerSubIndex) => {
                  const innerGroupNavItem = innerGroupItem as NavigationItem
                  return (
                    <NavItem
                      key={`${innerGroupNavItem.label}-${subIndex}-${innerSubIndex}`}
                      className={classNames(
                        'pl-6 p-0.5 transition-gradient duration-300',
                        {
                          'bg-primary-subtle-hovered [&>*]:bg-transparent font-circular-bold':
                            isActive(innerGroupNavItem),
                        },
                        {
                          'hover:bg-primary-subtle-hovered':
                            innerGroupNavItem.url !== pathname,
                        },
                      )}
                      href={innerGroupNavItem.url}
                      label={innerGroupNavItem.label}
                    />
                  )
                })}
            </Fragment>
          )
        })}
      </Sidenav.Group>
    </div>
  )
}
